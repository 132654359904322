import { useEffect, useMemo, useState } from "react";
import {
  PackageDetailsContainer,
  PackageDetailsContent,
  PackageDetailsHeader,
  PackageDetailsSwitcher,
  PackageDetailsSwitcherItem,
  Title,
} from "./Package.elements";
import {GridContainer} from "../Clips/Clip.elements"
import { usePackagesActions, usePackagesState } from "@/store";
import {
  FileDelimiter,
  FileItemContainer,
  FileItemIcon,
  FileList,
  FilePath,
} from "../UploadForm/UploadForm.elements";
import ClipCard from "../Clips/ClipCard";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { NoDataPlaceholder } from "@/icons";
import StatusSelector from "./status/StatusSelector"; // Import the refactored StatusSelector
import { useAuthState } from "@/store"; // To check admin role

const formatFileNames = (upload) => {
  const { directoryName, fileNames } = upload;
  if (!fileNames) return [];
  return fileNames
    .split(", ")
    .map((name) => name.replace(new RegExp(`^${directoryName}-`), ""));
};

export const PackageDetails = ({ uploadMetadataId, upload, directoryName, onStatusUpdate }) => {
  const [selected, setSelected] = useState("files"); // files | clips | status
  const {
    fetchDetails: { status },
    packageDetails,
  } = usePackagesState();
  const { fetchPackageDetails } = usePackagesActions();
  const { currentUser } = useAuthState();
  const { role } = currentUser || {};
  const isAdmin = role === "admin"; // Restrict status tab to admins

  const formattedFileNames = useMemo(() => formatFileNames(upload), [upload]);

  useEffect(() => {
    fetchPackageDetails(uploadMetadataId);
  }, [fetchPackageDetails, uploadMetadataId]);

  const emptyPlaceholder = (
    <Stack gap="24px" alignItems="center">
      <NoDataPlaceholder height="82px" />
      <Typography variant="h5">No data available</Typography>
    </Stack>
  );

  return (
    <PackageDetailsContainer>
      <PackageDetailsHeader>
        <Title color="white">
          {selected === "clips"
            ? "Clips"
            : selected === "status"
            ? "Status Management"
            : "Uploaded Files"}
        </Title>
        <PackageDetailsSwitcher>
          <PackageDetailsSwitcherItem
            isActive={selected === "files"}
            onClick={() => setSelected("files")}
          >
            Files
          </PackageDetailsSwitcherItem>
          <PackageDetailsSwitcherItem
            isActive={selected === "clips"}
            onClick={() => setSelected("clips")}
          >
            Clips
          </PackageDetailsSwitcherItem>
          {isAdmin && (
            <PackageDetailsSwitcherItem
              isActive={selected === "status"}
              onClick={() => setSelected("status")}
            >
              Status
            </PackageDetailsSwitcherItem>
          )}
        </PackageDetailsSwitcher>
      </PackageDetailsHeader>
      <PackageDetailsContent>
        {status === "loading" ? (
          <Stack alignItems="center">
            <CircularProgress size="60px" disableShrink color="white" />
          </Stack>
        ) : (
          <>
            {selected === "files" &&
              (formattedFileNames.length ? (
                <FileList>
                  {formatFileNames(upload).map((file, i) => (
                    <>
                      {i !== 0 && <FileDelimiter />}
                      <FileItemContainer key={file.path}>
                        <FileItemIcon />
                        <FilePath>{file}</FilePath>
                      </FileItemContainer>
                    </>
                  ))}
                </FileList>
              ) : (
                emptyPlaceholder
              ))}
            {selected === "clips" &&
              (packageDetails.length ? (
                <GridContainer>
                  {packageDetails.map((item) => (
                    <ClipCard file={item} key={item.id} />
                  ))}
                </GridContainer>
              ) : (
                emptyPlaceholder
              ))}
            {selected === "status" && (
              <StatusSelector
                currentStatus={upload?.status}
                folderName={directoryName} // Pass the directoryName here
                onSubmit={onStatusUpdate}
              />
            )}
          </>
        )}
      </PackageDetailsContent>
    </PackageDetailsContainer>
  );
};
