import React, { useState, useEffect } from "react";
import { ChevronIcon } from "@/icons";
import { Dropzone } from "./DropZone";
import PackageByContributorIdSelector from "../../packageDropdown/PackageByContributorIdSelector";
import {
  UploadTitleBlock,
  UploadTitleText,
  ButtonWrapper,
  UploadButton,
} from "../../UploadForm.elements";
import { TextField, FormControl, FormHelperText, Switch, FormControlLabel, Select, MenuItem } from "@mui/material";
import {
  CollapsibleContainer,
  HeaderDiv,
  ArrowIcon,
  Content,
  CollapsibleContainerGroup,
} from "./SubmissionUpload.elements";
import { useAuthState } from "@/store";
import { usePackagesActions } from "@/store/packagesSlice";


const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CollapsibleContainer>
      <HeaderDiv onClick={() => setIsOpen(!isOpen)}>
        {title}
        <ArrowIcon isOpen={isOpen}>
          <ChevronIcon />
        </ArrowIcon>
      </HeaderDiv>
      <Content isOpen={isOpen}>{children}</Content>
    </CollapsibleContainer>
  );
};

const SubmissionUpload = ({ handleNext }) => {
  const ACCEPTED_FORMATS = {
    clips: { "video/*": [] },
    stills: { "image/*": [] },
    metadata: {
      "application/json": [],
      "application/xml": [],
      "text/plain": [],
      "application/vnd.ms-excel": [], // .xls
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // .xlsx
    },
    releases: {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
    },
  };

  const { uploadPackage } = usePackagesActions();
  const { currentUser } = useAuthState();
  const [submissionTitle, setSubmissionTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [addToExistingPackage, setAddToExistingPackage] = useState(false);
  const [selectedPackageName, setSelectedPackageName] = useState(""); // Track the selected package
  const [category, setCategory] = useState("");

  // Separate states for each file type
  const [clips, setClips] = useState([]);
  const [stills, setStills] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [releases, setReleases] = useState([]);

  // Reset fields when the switch is toggled off
  useEffect(() => {
    if (!addToExistingPackage) {
      setSelectedPackageName(""); // Reset package name
      setCategory("");         // Reset category
      setSubmissionTitle("");     // Reset submission title
    }
  }, [addToExistingPackage]);

  // Autofill submission title when package name changes
  useEffect(() => {
    if (addToExistingPackage && selectedPackageName) {
      setSubmissionTitle(selectedPackageName);
    }
  }, [selectedPackageName, addToExistingPackage]);

  // Update form validity based on title, files, and package fields
  useEffect(() => {
    const hasFiles =
      clips.length > 0 ||
      stills.length > 0 ||
      metadata.length > 0 ||
      releases.length > 0;

    const isPackageValid =
      !addToExistingPackage || (selectedPackageName && category);

    setIsFormValid(hasFiles && submissionTitle.trim() && isPackageValid);
  }, [
    clips,
    stills,
    metadata,
    releases,
    submissionTitle,
    category,
  ]);

  // Common handler to set files for each file type
  const handleFilesAdded = (newFiles, setFiles) => {
    setFiles((prevFiles) => {
      const existingFileNames = prevFiles.map((file) => file.name);
      const filteredNewFiles = newFiles.filter(
        (file) => !existingFileNames.includes(file.name)
      );
      return [...prevFiles, ...filteredNewFiles];
    });
  };

  // Submit handler
  const handleSubmit = () => {
    setIsSubmitted(true);

    if (isFormValid) {
      // Create the files array with actual File objects
      const files = [...clips, ...stills, ...metadata, ...releases];

      // Construct filesInfo with the correct format
      const filesInfo = {};
      clips.forEach((file) => (filesInfo[file.name] = "CLIP"));
      stills.forEach((file) => (filesInfo[file.name] = "STILL"));
      metadata.forEach((file) => (filesInfo[file.name] = "METADATA"));
      releases.forEach((file) => (filesInfo[file.name] = "RELEASE"));

    const uploadMetadata = {
      submissionTitle,
      notes,
      filesInfo,
      startUploadTime: new Date().toISOString(),
      ...(addToExistingPackage && {
        fileCategory: category,
      }),
    };

      // Dispatch the action to upload files
      console.log("uploadMetadata before uploadPackage:", uploadMetadata);
      uploadPackage({ files, uploadMetadata });
      handleNext();
    }
  };

  return (
    <>
      <UploadTitleBlock>
        <UploadTitleText>SUBMISSION UPLOAD</UploadTitleText>
      </UploadTitleBlock>

      <form onSubmit={(e) => e.preventDefault()}>
        <CollapsibleContainerGroup>
          {/* File Upload Sections */}
          <CollapsibleSection title="Clips">
            <Dropzone
              files={clips}
              setFiles={setClips}
              onFilesAdded={(newFiles) => handleFilesAdded(newFiles, setClips)}
              acceptedFormats={ACCEPTED_FORMATS.clips}
            />
          </CollapsibleSection>

          <CollapsibleSection title="Stills">
            <Dropzone
              files={stills}
              setFiles={setStills}
              onFilesAdded={(newFiles) => handleFilesAdded(newFiles, setStills)}
              acceptedFormats={ACCEPTED_FORMATS.stills}
            />
          </CollapsibleSection>

          <CollapsibleSection title="Metadata">
            <Dropzone
              files={metadata}
              setFiles={setMetadata}
              onFilesAdded={(newFiles) =>
                handleFilesAdded(newFiles, setMetadata)
              }
              acceptedFormats={ACCEPTED_FORMATS.metadata}
            />
          </CollapsibleSection>

          <CollapsibleSection title="Releases">
            <Dropzone
              files={releases}
              setFiles={setReleases}
              onFilesAdded={(newFiles) =>
                handleFilesAdded(newFiles, setReleases)
              }
              acceptedFormats={ACCEPTED_FORMATS.releases}
            />
          </CollapsibleSection>
        </CollapsibleContainerGroup>

        {/* Submission Title (Required) */}
        <FormControl fullWidth margin="normal">
          <TextField
            label="Submission Title"
            placeholder="Enter the submission title"
            value={submissionTitle}
            onChange={(e) => setSubmissionTitle(e.target.value)}
            required
          />
          {isSubmitted && !submissionTitle.trim() && (
            <FormHelperText error>Submission title is required</FormHelperText>
          )}
        </FormControl>

        {/* Notes (Optional) */}
        <FormControl fullWidth margin="normal">
          <TextField
            label="Notes"
            placeholder="Optional notes about the submission"
            multiline
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </FormControl>

        {/* Add Files to Existing Package */}
        <FormControlLabel
          control={
            <Switch
              checked={addToExistingPackage}
              onChange={(e) => setAddToExistingPackage(e.target.checked)}
              color="primary"
            />
          }
          label="Add files to existing package?"
        />

        {addToExistingPackage && (
          <>
            {/* Package Selector */}
            <PackageByContributorIdSelector
              contributorId={currentUser?.id}
              onSelectPackage={setSelectedPackageName}
            />

            {/* Package Type */}
            <FormControl fullWidth margin="normal">
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select File Category
                </MenuItem>
                <MenuItem value="HI_RES">Hi-Res</MenuItem>
                {/* <MenuItem value="CORRECTION">Correction</MenuItem> */}
                <MenuItem value="MISSING">Missing</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </Select>
            </FormControl>
          </>
        )}

        <ButtonWrapper>
          <UploadButton onClick={handleSubmit} disabled={!isFormValid}>
            Submit
          </UploadButton>
        </ButtonWrapper>
      </form>
    </>
  );
};

export default SubmissionUpload;