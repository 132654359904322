import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Dialog, DialogContent, IconButton } from "@mui/material";

export const Container = styled.div`
  background-color: #2c2f3f;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  color: #ffffff;
  font-family: Arial, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const PackageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1008px) {
    ${({ packagesView}) => packagesView === 'grid' && css`
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        flex-basis: 32%;
        flex-basis: 400px;
        align-self: stretch;
        display: flex;
        flex-direction: column;

        &:nth-of-type(n+3) {
          margin-top: 5px;
        }
      }
    `}
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding-top: 15px;
`;

export const Title = styled.h2`
  color: ${({ color }) => color || "#7C74CF"};
  font-size: 18px;
  margin: 0;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 12px;
`;

export const RatingSection = styled.div`
  display: flex;
  align-items: center;
`;

export const Star = styled.span`
  color: ${(props) => (props.filled ? "#FFD700" : "#666666")};
  font-size: 20px;
  margin-right: 2px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end; /* Align everything to the right */
  align-items: center;
  font-size: 12px;
  color: #d9d9e3;
  margin-top: 16px;
  padding-right: 20px; /* Adds space between the right edge and the content */
`;

// UploadTime styled component
export const UploadTime = styled.div`
  font-size: 12px;
  color: #d9d9e3;
  text-align: right;
`;

export const PackageDialog = styled(Dialog)`
  > div > .MuiPaper-root {
    margin: 10;
    width: 90vw;
    max-width: 1090px;
  }
`;

export const PackageDialogContent = styled(DialogContent)`
  background-color: #2c2f3f;
  padding: 10px;
  min-width: min(100vw, 600px);
`;

export const PackageDialogDelimiter = styled.hr`
  border-color: #8095b72b;
  border-width: 1px 0 0;
`;

export const PackageDetailsContainer = styled.div`
  padding: 24px;
`;

export const PackageDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PackageDetailsSwitcher = styled.div`
  border: 1px solid #5d62b6;
  background: #202c3dbf;
  display: flex;
  border-radius: 8px;
`;

export const PackageDetailsSwitcherItem = styled.div`
  padding: 8px;
  border-radius: inherit;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      background: #5d62b6;
      box-shadow: inset 2px 4px 4px #00000040;
      cursor: initial;
    `}
`;

export const PackageDetailsContent = styled.div`
  padding: 16px 0;
`;


export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right */
  gap: 16px;
  margin-bottom: 16px;

  /* For consistent layout alongside the tab switcher */
  > * {
    flex-shrink: 0;
  }

  select {
    min-width: 200px; /* Ensures the dropdown has a consistent width */
  }
`;
