import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  FormContainer,
  TitleBlock,
  TitleText,
  FormWrapper,
  ButtonWrapper,
} from "./StatusSelector.elements";
import StatusService from "@/Service/StatusService";

const StatusSelector = ({ currentStatus, folderName, onSubmit }) => {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const STATUS_MAP = {
    "Uploaded": "UPLOADED",
    "Edited": "EDITED",
    "Tagged": "TAGGED",
    "Distributed": "PUBLISHED",
    "Approved": "APPROVED",
    "Rejected": "REJECTED",
    "Canceled": "CANCELLED",
    "Retouching": "RETOUCHED",
    "Grading": "COLOR_GRADING",
    "Model Matching": "MODEL_MATCHED",
  };
  

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      // Convert user-friendly status to API value
      const apiStatus = STATUS_MAP[selectedStatus];
      await StatusService.updateStatusForDirectories(folderName, apiStatus);
      console.log(`Status updated to: ${apiStatus}`);
      onSubmit(apiStatus); // Notify parent with the API value
    } catch (err) {
      console.error("Failed to update status:", err);
      setError("Failed to update status. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormWrapper>
    <FormContainer>
      <TitleBlock>
        <TitleText>Update Status</TitleText>
      </TitleBlock>
      <FormControl fullWidth>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          {Object.keys(STATUS_MAP).map((label) => (
            <MenuItem key={label} value={label}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonWrapper>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: "10px" }}
        disabled={loading}
      >
        {loading ? "Updating..." : "Submit"}
      </Button>
      </ButtonWrapper>
    </FormContainer>
    </FormWrapper>
  );
};

export default StatusSelector;
