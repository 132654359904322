import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "@emotion/styled";

import {
  UploadIcon,
  UploadIconBlock,
  FileItemContainer,
  DropTitleText,
  DropNormalText,
  FileList,
  FilePath,
  FileSize,
  DropHighlightedText,
  FileItemIcon,
  DeleteIcon,
  FileDelimiter,
} from "../../UploadForm.elements";
import {FormatSize} from "../../../Util/Utils.elements";

const getColor = (props) => {
  if (props.isDragAccept) return "#00e676";
  if (props.isDragReject) return "#ff1744";
  if (props.isDragActive) return "#2196f3";
  return "#B2B2B2D9";
};

const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-width: 3px;
  border-radius: 8px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #202C3D8C;
  color: #fff;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`;

export const Dropzone = ({ files, setFiles, onFilesAdded, acceptedFormats }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptedFormats,
    onDrop: (acceptedFiles) => {
      console.log("Files added:", acceptedFiles);

      setFiles((prevFiles) => {
      console.log("Existing files:", prevFiles);
        const existingFileNames = prevFiles.map((file) => file.name);
        const filteredNewFiles = acceptedFiles.filter(
          (file) => !existingFileNames.includes(file.name)
        );

        console.log("Filtered new files (unique):", filteredNewFiles);
        const updatedFiles = [...prevFiles, ...filteredNewFiles];

        // Notify parent component
        onFilesAdded(updatedFiles);

        return updatedFiles;
      });
    },
  });

  const handleDelete = (file) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((f) => f !== file);

      // Notify parent about the updated files after deletion
      onFilesAdded(updatedFiles);

      return updatedFiles;
    });
  };

  const acceptedFileItemsWithDel = files.map((file, i) => (
    <React.Fragment key={file.path || file.name}>
      {i !== 0 && <FileDelimiter />}
      <FileItemContainer>
        <FileItemIcon />
        <FilePath>{file.path || file.name}</FilePath>
        <FileSize>
          <FormatSize sizeInBytes={file.size} />
        </FileSize>
        <DeleteIcon onClick={() => handleDelete(file)}>Delete</DeleteIcon>
      </FileItemContainer>
    </React.Fragment>
  ));

  return (
    <div>
        <section>
          <DropContainer
            {...getRootProps({
              className: "dropzone",
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <DropTitleText>Add your assets</DropTitleText>
              <UploadIconBlock>
              <UploadIcon />
              </UploadIconBlock>
              <DropNormalText>
                Drag & drop your files or
                {' '}
                <DropHighlightedText>
                  browse files
                </DropHighlightedText>
              </DropNormalText>
          </DropContainer>
          <div style={{ padding: 5 }}></div>
          <FileList>
            {acceptedFileItemsWithDel}
          </FileList>
          <div style={{ padding: 10 }}></div>
        </section>
    </div>
  );
};
