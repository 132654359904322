import { useEffect, useState } from "react";
import {
  PackageDetailsContainer,
  PackageDetailsContent,
  PackageDetailsHeader,
  PackageDetailsSwitcher,
  PackageDetailsSwitcherItem,
  Title,
} from "./Package.elements";
import { GridContainer } from "../Clips/Clip.elements";
import {
  FileDelimiter,
  FileItemContainer,
  FileItemIcon,
  FileList,
  FilePath,
} from "../UploadForm/UploadForm.elements";
import ClipCard from "../Clips/ClipCard";
import { FilterWrapper } from "./Package.elements";
import { CircularProgress, Stack, Typography, Select, MenuItem } from "@mui/material";
import { NoDataPlaceholder } from "@/icons";
import StatusSelector from "./status/StatusSelector";
import { useAuthState } from "@/store";
import { usePackagesActions, usePackagesState } from "@/store";
import { useSourceFilesActions, useSourceFilesState } from "@/store/sourceFilesSlice";

export const PackageDetails = ({ uploadMetadataId, directoryName, onStatusUpdate }) => {
  const [selected, setSelected] = useState("files"); // files | clips | status
  const [filterStatus, setFilterStatus] = useState("All"); // Filter by status (All, Approved, etc.)

  const { fetchDetails: packageStatus, packageDetails } = usePackagesState();
  const { fetchPackageDetails } = usePackagesActions();

  const { fetchSourceFiles } = useSourceFilesActions();
  const { sourceFiles = [], status: sourceFilesStatus = "idle" } = useSourceFilesState() || {}; // Default fallback for undefined state

  const { currentUser } = useAuthState();
  const { role } = currentUser || {};
  const isAdmin = role === "admin"; // Restrict status tab to admins

  // Fetch package details and source files
  useEffect(() => {
    fetchPackageDetails(uploadMetadataId);
    fetchSourceFiles({page: 0, size: 100 }); // Fetch all source files
  }, [fetchPackageDetails, fetchSourceFiles, uploadMetadataId]);

  // Filter files based on the selected filter status
  const filteredFiles = sourceFiles.filter((file) =>
    filterStatus === "All" ? true : file.status === filterStatus
  );

  const emptyPlaceholder = (
    <Stack gap="24px" alignItems="center">
      <NoDataPlaceholder height="82px" />
      <Typography variant="h5">No files available</Typography>
    </Stack>
  );

  return (
    <PackageDetailsContainer>
      <PackageDetailsHeader>
        <Title color="white">
          {selected === "clips"
            ? "Clips"
            : selected === "status"
            ? "Status Management"
            : "Uploaded Files"}
        </Title>
        <PackageDetailsSwitcher>
          <PackageDetailsSwitcherItem
            isActive={selected === "files"}
            onClick={() => setSelected("files")}
          >
            Files
          </PackageDetailsSwitcherItem>
          <PackageDetailsSwitcherItem
            isActive={selected === "clips"}
            onClick={() => setSelected("clips")}
          >
            Clips
          </PackageDetailsSwitcherItem>
          {isAdmin && (
            <PackageDetailsSwitcherItem
              isActive={selected === "status"}
              onClick={() => setSelected("status")}
            >
              Status
            </PackageDetailsSwitcherItem>
          )}
        </PackageDetailsSwitcher>
      </PackageDetailsHeader>
      <PackageDetailsContent>
        {/* Loading Indicator */}
        {packageStatus.status === "loading" || sourceFilesStatus === "loading" ? (
          <Stack alignItems="center">
            <CircularProgress size="60px" disableShrink color="white" />
          </Stack>
        ) : (
          <>
            {/* Files Tab */}
            {selected === "files" && (
              <>
                {/* Filter Dropdown */}
                <FilterWrapper>
                  <Typography>Filter by Status:</Typography>
                  <Select
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="APPROVED">Approved</MenuItem>
                    <MenuItem value="REJECTED">Rejected</MenuItem>
                    <MenuItem value="NOT_CURATED">Not Curated</MenuItem>
                  </Select>
                </FilterWrapper>

                {/* File List */}
                {filteredFiles.length > 0 ? (
                  <FileList>
                    {filteredFiles.map((file, i) => (
                      <>
                        {i !== 0 && <FileDelimiter />}
                        <FileItemContainer key={file.id}>
                          <FileItemIcon />
                          <FilePath>{file.renamedFilename}</FilePath>
                          <Typography
                            style={{
                              marginLeft: "auto",
                              color:
                                file.status === "APPROVED"
                                  ? "green"
                                  : file.status === "REJECTED"
                                  ? "red"
                                  : "gray",
                            }}
                          >
                            {file.status.replace("_", " ")} {/* Format status nicely */}
                          </Typography>
                        </FileItemContainer>
                      </>
                    ))}
                  </FileList>
                ) : (
                  emptyPlaceholder
                )}
              </>
            )}

            {selected === "clips" &&
              (packageDetails.length > 0 ? (
                <GridContainer>
                  {packageDetails.map((item) => (
                    <ClipCard file={item} key={item.id} />
                  ))}
                </GridContainer>
              ) : (
                emptyPlaceholder
              ))}

            {/* Status Management Tab */}
            {selected === "status" && (
              <StatusSelector
                currentStatus={packageDetails?.status}
                folderName={directoryName}
                onSubmit={onStatusUpdate}
              />
            )}
          </>
        )}
      </PackageDetailsContent>
    </PackageDetailsContainer>
  );
};
