import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useAuthState, useContributorsActions } from "@/store";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  SignInContainer,
  FormContainer,
  AuthTitleBlock,
  TitleText,
  FormWrapper,
  ButtonWrapper,
  SubmitButton,
} from "../AuthForm/AuthSteps/Auth.elements";

import { FormError, FormGroup } from "../Util/Form.elements";

const AddNewUser = ({ onClose }) => {
  const { currentUser } = useAuthState();
  const { role } = currentUser || {};
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { createContributor } = useContributorsActions();
  const canManageAdmin = role === "admin";
  const canManageManager = role === "manager";

  const availableRoles = canManageAdmin
    ? ["admin", "manager", "contributor"]
    : canManageManager
    ? ["manager", "contributor"]
    : [];

  const onSubmit = async (payload) => {
    const result = await createContributor(payload);
    console.log(result);
    if (result.meta.requestStatus === "fulfilled") {
      onClose();
    }
  };

  return (
    <SignInContainer>
      <FormContainer>
        <AuthTitleBlock>
          <TitleText>ADD NEW USER</TitleText>
        </AuthTitleBlock>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <TextField
              fullWidth
              label="Name"
              placeholder="Enter the name"
              {...register("name", {
                required: "Name is required",
              })}
              error={!!errors.name} // Shows error state if there's a validation error
              helperText={errors.name?.message} // Displays the error message
            />

            <TextField
              fullWidth
              label="Supplier ID"
              placeholder="Enter the Supplier id"
              {...register("xpId", {
                required: "Supplier id is required",
              })}
              error={!!errors.xpId} // Shows error state if there's a validation error
              helperText={errors.xpId?.message} // Displays the error message
            />

            <TextField
              fullWidth
              label="Email"
              placeholder="Enter your email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Invalid email address",
                },
              })}
              error={!!errors.email} // Shows error state if there's a validation error
              helperText={errors.email?.message} // Displays the error message
            />

            <FormGroup>
              <FormControl fullWidth error={!!errors.role}>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Controller
                  name="role"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Role is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="role-select-label"
                      label="Role"
                      disabled={availableRoles.length === 0}
                    >
                      {availableRoles.map((roleOption) => (
                        <MenuItem key={roleOption} value={roleOption}>
                          {roleOption}
                        </MenuItem>
                      ))}
                      {availableRoles.length === 0 && (
                        <MenuItem value="" disabled>
                          No roles available
                        </MenuItem>
                      )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.role && <FormError>{errors.role.message}</FormError>}
            </FormGroup>
          </FormWrapper>

          <ButtonWrapper>
            <SubmitButton
              buttonState="auth"
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              ADD
            </SubmitButton>
          </ButtonWrapper>
        </form>
      </FormContainer>
    </SignInContainer>
  );
};

export default AddNewUser;
