/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from "react";

import { useAuthState, useContributorsActions, useContributorsState } from "@/store";
import useClickOutside from "@/components/hooks/useClickOutside";
import {
  FormContainer,
  FormGroup,
  DropdownWrapper,
  FormLabel,
  SelectTrigger,
  ChevronIcon,
  FilterIcon,
  SelectedIcon,
  DropdownMenu,
  Input,
  DropdownItem,
  DropdownDivider,
  MobileSelectTrigger,
} from "./ContributorSelector.elements";
import { useIsMobile } from "../hooks";

const defaultValue = "All Contributors";

const ContributorsSelector = () => {
  const { currentUser, fetch: { status: fetchAuthStatus } } = useAuthState();
  const { role } = currentUser || {};
  const { contributors, fetch: { status: fetchContributorsStatus } } = useContributorsState();
  const { fetchContributors } = useContributorsActions();
  const [filteredContributors, setFilteredContributors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedContributorEmail, setSelectedContributorEmail] = useState(defaultValue);
  const { selectContributorId } = useContributorsActions();
  const isMobile = useIsMobile();

  const dropdownRef = useRef(null);

  const canViewContributors = fetchAuthStatus === 'succeeded' && (role === "admin" || role === "manager");

  const isAdmin = fetchAuthStatus === 'succeeded' && role === "admin";
  // const isManager = !loading && userDocument?.roles?.includes("manager");
  // console.log("isAdmin value is: ", isAdmin)


  useEffect(() => {
    if (fetchContributorsStatus === 'idle' && canViewContributors) {
      fetchContributors();
    }
  }, [canViewContributors, fetchContributors, fetchContributorsStatus]);

  useEffect(() => {
    const filtered = contributors.filter((contributor) =>
      contributor.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredContributors(filtered);
  }, [searchTerm, contributors]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectContributor = useCallback((contributor) => {
    const selectedEmail = contributor.email || contributor; // Show email in dropdown
    setSelectedContributorEmail(selectedEmail);
    setShowDropdown(false);
    selectContributorId(contributor.id); // Pass the contributor's ID to the parent component
  }, [selectContributorId]);

  useClickOutside(dropdownRef, () => setShowDropdown(false));

  if (!isAdmin) {
    return (
      <FormContainer>
        <FormGroup>
          <FormLabel>Your Uploads</FormLabel>
          <div>{currentUser?.name}</div>
          {currentUser.uid} {/* Pass current user's ID */}
        </FormGroup>
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <FormGroup>
        <DropdownWrapper ref={dropdownRef}>
          {isMobile ? (
            <MobileSelectTrigger onClick={() => setShowDropdown(!showDropdown)}>
              <FilterIcon />
            </MobileSelectTrigger>
          ) : (
            <SelectTrigger onClick={() => setShowDropdown(!showDropdown)}>
              <FilterIcon />
              {selectedContributorEmail}
              <ChevronIcon />
            </SelectTrigger>
          )}
          
          {showDropdown && (
            <DropdownMenu>
              <Input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <DropdownItem onClick={() => handleSelectContributor({ email: "All Contributors", id: "" })}>
                All Contributors
                {selectedContributorEmail === 'All Contributors' && (
                  <SelectedIcon />
                )}
              </DropdownItem>
              <DropdownItem onClick={() => handleSelectContributor(currentUser)}>
                Just Me
                {selectedContributorEmail === currentUser.email && (
                  <SelectedIcon />
                )}
              </DropdownItem>
              <DropdownDivider />
              {filteredContributors.map((contributor) => (
                <DropdownItem
                  key={contributor.id}
                  onClick={() => handleSelectContributor(contributor)}
                >
                  <div>
                    <strong>{contributor.name}</strong>
                    <div style={{ fontSize: "12px", color: "#aaa" }}>
                      {contributor.email}
                    </div>
                  </div>
                  {selectedContributorEmail === contributor.email && (
                    <SelectedIcon />
                  )}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </DropdownWrapper>
      </FormGroup>
    </FormContainer>
  );
};

export default ContributorsSelector;
