import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from './loadingSlice';
import { showAlert, hideAlert } from './alertSlice';
import PackageService from '@/Service/PackageService';

export const fetchClips = createAsyncThunk(
  'clips/fetchClips',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      const response = await PackageService.getClips(params);
      dispatch(hideLoading());
      return response.data;
    } catch (error) {
      console.error("[fetchClips] Error:", error); // Log the error
      dispatch(hideLoading());
      dispatch(showAlert("error", "There was an error while fetching the uploads."));
      return rejectWithValue(error);
    }
  }
);

const clipsSlice = createSlice({
  name: 'clips',
  initialState: {
    clips: [],
    totalPages: 0,
    currentPage: 0,
    searchTerm: '',
    startDate: undefined,
    endDate: undefined,
    filteredClips: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    setClipSearchTerm: (state, action) => {
      console.log("[setClipSearchTerm] Search Term:", action.payload);
      state.searchTerm = action.payload;
      state.currentPage = 0;
    },
    setClipStartDate: (state, action) => {
      console.log("[setClipStartDate] Start Date:", action.payload);
      state.startDate = action.payload;
      state.currentPage = 0;
    },
    setClipEndDate: (state, action) => {
      console.log("[setClipStartDate] End Date:", action.payload);
      state.endDate = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      console.log("[setPage] Current Page:", action.payload);
      state.currentPage = action.payload;
    },
    resetFilters: (state) => {
      console.log("[resetFilters] Resetting filters.");
      state.searchTerm = '';
      state.startDate = null;
      state.endDate = null;
      state.currentPage = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClips.pending, (state) => {
        console.log("[fetchClips.pending] Fetching clips...");
        state.status = 'loading';
      })
      .addCase(fetchClips.fulfilled, (state, action) => {
        console.log("[fetchClips.fulfilled] Fetched clips:", action.payload.content);
        state.status = 'succeeded';
        state.clips = action.payload.content;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchClips.rejected, (state, action) => {
        console.error("[fetchClips.rejected] Error:", action.error.message);
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const {
  setClipSearchTerm,
  setClipStartDate,
  setClipEndDate,
  setPage,
  resetFilters,
} = clipsSlice.actions;

export const useClipsState = () => {
  const state = useSelector((state) => state.clips);
  console.log("[useClipsState] Current state:", state);
  return state;
};

export const useClipsActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    fetchClips: (params) => {
      console.log("[useClipsActions] Fetching clips with params:", params);
      return dispatch(fetchClips(params));
    },
    setClipSearchTerm: (term) => {
      console.log("[useClipsActions] Setting search term:", term);
      return dispatch(setClipSearchTerm(term));
    },
    setClipStartDate: (term) => {
      console.log("[setClipStartDate] Setting start date:", term);
      return dispatch(setClipStartDate(term));
    },
    setClipEndDate: (term) => {
      console.log("[setClipEndDate] Setting end date:", term);
      return dispatch(setClipEndDate(term));
    },
    setPage: (page) => {
      console.log("[useClipsActions] Setting page:", page);
      return dispatch(setPage(page));
    },
    resetFilters: () => {
      console.log("[useClipsActions] Resetting filters.");
      return dispatch(resetFilters());
    },
  }), [dispatch]);
};

export default clipsSlice.reducer;
