import {
  Navbar,
  Header,
  GlobalLoader,
  GlobalAlert,
} from "@/components";
import { Route, Routes } from "react-router-dom";
import GlobalStyle, { AppLayout, AppMain, AppContent } from "./globalStyles";
import HomePage from "./pages/HomePage/HomePage";
import UploadsPage from "./pages/UploadsPage/UploadsPage";
import SignInPage from "./pages/AuthPages/SIgnInPage";
import ForgotPasswordPage from "./pages/AuthPages/ForgotPasswordPage";
import PasswordSentPage from "./pages/AuthPages/PasswordSentPage";
// import UploadInputGroup from "./components/UploadForm/UploadInputGroup";
import RoleProtectedRoute from "./components/AuthForm/RoleProtectedRoute";
import AddUserPage from "./pages/AddUserPage/UserManagementPage";
import { useModalManager } from "./components/hooks/useModalManager";
import ClipPage from "./pages/ClipPage/ClipsPage";
import { CssBaseline } from "@mui/material";
import {
  useAuthState,
  useListenToAuthChanges,
  useMobileMenuActions,
} from "@/store";
import ChangePasswordGroup from "./components/Profile/ChangePassword/ChangePasswordGroup";
import AddNewUserPage from "./pages/AddUserPage/AddNewUserPage";

function App() {
  const { currentUser } = useAuthState();

  useListenToAuthChanges();

  const { setMobileMenuOpen } = useMobileMenuActions();

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const { showChangePasswordModal, toggleChangePasswordModal } =
    useModalManager(closeMobileMenu);

  const { showAddUserModal, toggleAddUserdModal } =
    useModalManager(closeMobileMenu);

  // const isUploading = true;

  const handleClose = (toggleModal) => {
    toggleModal();
    setMobileMenuOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <GlobalAlert />
      <GlobalLoader />
      <AppLayout>

        {showChangePasswordModal && (
          <ChangePasswordGroup
            show={showChangePasswordModal}
            handleClose={() => handleClose(toggleChangePasswordModal)}
          />
        )}

        {showAddUserModal && (
          <AddNewUserPage
            show={showAddUserModal}
            handleClose={() => handleClose(toggleAddUserdModal)}
          />
        )}

        <AppMain>
          {currentUser && <Navbar />}
          <AppContent>
            {currentUser && <Header />}
            <Routes>
              <Route
                path="/"
                element={
                  <RoleProtectedRoute
                    allowedRoles={["contributor", "manager", "admin"]}
                  >
                    <HomePage currentUser={currentUser} />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/uploads"
                element={
                  <RoleProtectedRoute
                    allowedRoles={["contributor", "manager", "admin"]}
                  >
                    <UploadsPage currentUser={currentUser} />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/clips"
                element={
                  <RoleProtectedRoute
                    allowedRoles={["contributor", "manager", "admin"]}
                  >
                    <ClipPage currentUser={currentUser} />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/manageUsers"
                element={
                  <RoleProtectedRoute allowedRoles={["manager", "admin"]}>
                    <AddUserPage />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/signin"
                element={
                  <RoleProtectedRoute allowUnauthenticated={true}>
                    <SignInPage />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/resetpassword"
                element={
                  <RoleProtectedRoute allowUnauthenticated={true}>
                    <ForgotPasswordPage />
                  </RoleProtectedRoute>
                }
              />
              <Route
                path="/passwordsent"
                element={
                  <RoleProtectedRoute allowUnauthenticated={true}>
                    <PasswordSentPage />
                  </RoleProtectedRoute>
                }
              />
            </Routes>
          </AppContent>
        </AppMain>
        {/* <Footer /> */}
      </AppLayout>
    </>
  );
}

export default App;
