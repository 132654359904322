import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import UploadService from "@/Service/UploadService"; // Import UploadService
import { useAlertActions } from "@/store/alertSlice"; // Still using alert actions for error notifications

const PackageByContributorIdSelector = ({ contributorId, onSelectPackage }) => {
  const [packages, setPackages] = useState([]); // Manage local package state
  const [selectedPackage, setSelectedPackage] = useState("");
  const [status, setStatus] = useState("idle"); // Track loading status
  const [error, setError] = useState(null); // Track error state
  const { showAlert } = useAlertActions();

  // Fetch packages when contributorId changes
  useEffect(() => {
    const fetchPackages = async () => {
      if (!contributorId) return; // Skip if no contributorId
      setStatus("loading");
      setError(null); // Reset error state
      try {
        const response = await UploadService.getPackagesByContributorId(contributorId);
        setPackages(response); // Save response as package list
        setStatus("succeeded");
      } catch (err) {
        setError("Failed to fetch packages");
        showAlert("error", err.message || "Failed to fetch packages."); // Show error message
        setStatus("failed");
      }
    };

    fetchPackages();
  }, [contributorId, showAlert]);

  // Handle dropdown selection change
  const handleSelectChange = (event) => {
    const packageName = event.target.value;
    setSelectedPackage(packageName);
    if (onSelectPackage) onSelectPackage(packageName);
  };

  return (
    <FormControl fullWidth margin="normal" error={!!error}>
      <Select
        value={selectedPackage}
        onChange={handleSelectChange}
        displayEmpty
        inputProps={{ "aria-label": "Select a package" }}
      >
        <MenuItem value="" disabled>
          Select a Package
        </MenuItem>
        {packages.length > 0 ? (
          packages.map((pkg, index) => (
            <MenuItem key={index} value={pkg}>
              {pkg}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No packages available</MenuItem>
        )}
      </Select>
      {status === "loading" && <FormHelperText>Loading packages...</FormHelperText>}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default PackageByContributorIdSelector;
