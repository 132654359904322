import React from "react";
import { StatusWrapper, StatusText, SvgWrapper } from "./PieChart.elements";

const statusMapping = {
  Uploaded: ["READY_FOR_CAT_DV", "CAT_DV_INGESTED", "UPLOADED"],
  Processing: ["READY_TO_UPLOAD_TO_PROVIDER", "IN_PROGRESS_UPLOAD_TO_PROVIDER"],
  Approved: ["APPROVED"],
  Retouching: ["RETOUCHED"],
  Grading: ["COLOR_GRADING"],
  ModelMatching: ["MODEL_MATCHED"],
  Edited: ["EDITED"],
  Tagged: ["TAGGED"],
  Distributed: ["PUBLISHED"],
  Rejected: ["REJECTED"],
};

const mapBackendStatusToFrontend = (backendStatus) => {
  // Map backend status to frontend status
  for (const [frontendStatus, backendStatuses] of Object.entries(
    statusMapping
  )) {
    if (backendStatuses.includes(backendStatus)) {
      return frontendStatus;
    }
  }
  return "Unknown"; // Default if status doesn't match any mapped status
};

const StatusPieChart = ({ status }) => {
  const frontendStatus = mapBackendStatusToFrontend(status);
  // Map status to color and percentage
  const statusMap = {
    Processing: {color: "#8095B7", percentage: 12},
    Uploaded: { color: "#3E79BD", percentage: 25 },
    Approved: {color: "#37B653", percentage: 35 },
    Edited: { color: "#F5C754", percentage: 50 },
    ModelMatching: {color: "#775289", percentage: 55 },
    Retouching: {color: "#F58688", percentage: 60},
    Grading: {color: "#F58688", percentage: 60 },
    Tagged: { color: "#F5A222", percentage: 75 },
    Distributed: { color: "#69BD44", percentage: 100 },
    Unknown: { color: "#D9D9D9", percentage: 0 },
    Rejected: { color: "#F15E6B", percentage: 100 },
    Canceled: { color: "#F15E6B", percentage: 100 },
  };

  const { color, percentage } = statusMap[frontendStatus];

  const radius = 16;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <StatusWrapper>
      <SvgWrapper viewBox="0 0 40 40">
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          stroke="#2C2F3F"
          strokeWidth="8"
        />
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth="8"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          transform="rotate(-90 20 20)"
        />
      </SvgWrapper>
      <StatusText>{frontendStatus}</StatusText>
    </StatusWrapper>
  );
};

export default StatusPieChart;
