
import { FaBars } from 'react-icons/fa';
import { useAuthState, useMobileMenuState, useMobileMenuActions } from '@/store';
import { Spacer } from '@/globalStyles';
import { UploadProgress, UserProfile, UploadClips } from "@/components";
import ContributorsSelector from "@/components/Util/ContributorsSelector";
import { HeaderPlaceholder, HeaderContainer, MobileNavIcon } from "./Header.elements";
import { useIsMobile } from '../hooks';


export const Header = () => {
  const isMobile = useIsMobile();
  const { isSidebarCollapsed } = useMobileMenuState();
  const { setMobileMenuOpen } = useMobileMenuActions();

  const { currentUser: { role } } = useAuthState();
  const isAdmin = role === "admin";
  const isManager = role === "manager";

  return (
    <HeaderPlaceholder>
      <HeaderContainer isSidebarCollapsed={isSidebarCollapsed}>

        {isMobile && (
          <MobileNavIcon onClick={() => setMobileMenuOpen(true)}>
            <FaBars />
          </MobileNavIcon>
        )}
        {(isAdmin || isManager) && <ContributorsSelector />}
        {/* <AffiliateSelector /> */}

        <Spacer />

        <UploadClips />
        <UploadProgress />
        <UserProfile />

      </HeaderContainer>
    </HeaderPlaceholder>
  )
};
