import { configureStore } from '@reduxjs/toolkit';
import contributorsReducer from './contributorsSlice';
import mobileMenuReducer from './mobileMenuSlice';
import authReducer from './authSlice';
import alertReducer from './alertSlice';
import loadingReducer from './loadingSlice';
import statsReducer from './statsSlice';
import packagesReducer from './packagesSlice';
import clipsReducer from './clipsSlice';
import sourceFilesReducer from './sourceFilesSlice';

export const store = configureStore({
  reducer: {
    contributors: contributorsReducer,
    mobileMenu: mobileMenuReducer,
    auth: authReducer,
    alert: alertReducer,
    loading: loadingReducer,
    stats: statsReducer,
    packages: packagesReducer,
    clips: clipsReducer,
    sourceFiles: sourceFilesReducer,
  },
});
