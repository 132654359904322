import React, { useEffect, useState } from "react";
import { useContributorsState, usePackagesActions, usePackagesState } from "@/store";
import SearchPanel from "../Util/SearchPanel";
import PackageSection from "./PackageSection";
import Pagination from "../Util/Pagination";
import {PackageWrapper} from "./Package.elements";

const PackageGroupSelection = (props) => {
  const { packages, totalPages, packagesView } = usePackagesState();
  const { fetchPackages } = usePackagesActions();
  const { selectedContributorId, affiliate } = useContributorsState();

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useState({
    country: null,
    searchTerm: null,
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    const { searchTerm, country, startDate, endDate } = searchParams;
    fetchPackages({
      page: currentPage,
      pageSize,
      contributors: selectedContributorId,
      affiliate,
      packageName: searchTerm,
      country,
      fromDate: startDate,
      toDate: endDate,
    });
  }, [
    fetchPackages,
    currentPage,
    pageSize,
    selectedContributorId,
    affiliate,
    searchParams
  ]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    console.log("newPageSize: ", newSize);
    setPageSize(newSize);
    setCurrentPage(0);
  };

  //todo1: implement search and add params to SearchPanel like in CLipsGroup done
  //todo2: return the switcher for view

  return (
    <>
      <SearchPanel setSearchParams={setSearchParams} hasViewSwitcher />
      <PackageWrapper packagesView={packagesView}>
      {packages.map((pkg) => (
        <PackageSection
          key={pkg.id}
          uploadMetadataId={pkg.id}
          upload={pkg}
          packageName={pkg.packageName}
          description={pkg.description}
          directoryName={pkg.directoryName}
          dateShot={new Date(pkg.createdAt * 1000).toLocaleDateString("en-GB", {
            weekday: "short",
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
          status={pkg.status}
        />    
      ))}
      </PackageWrapper>  
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};

export default PackageGroupSelection
