import React, { useState } from "react";
import {
  Container,
  Header,
  RatingSection,
  Star,
  Title,
  Description,
  UploadTime,
  Footer,
  PackageDialog,
  PackageDialogContent,
  PackageDialogDelimiter,
} from "./Package.elements";
import StatusPieChart from "./StatusPieChart"; // Import the pie chart component
import { AbsCrossButton } from "../CrossButton/AbsCrossButton";
import { PackageDetails } from "./PackageDetails";
import { StarFilled, StarOutlined } from "@/icons"

const PackageSection = ({
  uploadMetadataId,
  upload,
  packageName,
  directoryName,
  description,
  dateShot,
  status: initialStatus, // Rename the prop to avoid conflict with state
}) => {
  const [status, setStatus] = useState(initialStatus); // Manage status locally
  const [isOpen, setIsOpen] = useState(false);
  // Define a method to get stars based on the status
  const renderStars = (rating) => {
    let starsFilled = 0;
    if (rating) {
      starsFilled = rating;
    }
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= starsFilled ? <StarFilled /> : <StarOutlined />
      );
    }
    return stars;
  };

  const handleStatusUpdate = (newStatus) => {
    setStatus(newStatus); // Update the status in the local state
    console.log(`Package status updated to: ${newStatus}`);
  };

  return (
    <>
    <Container onClick={() => setIsOpen(true)}>
      <Header>
        <Title>{packageName}</Title>
        <RatingSection>{renderStars(upload?.rating)}</RatingSection>
      </Header>
      <Description>{description}</Description>
      <Footer style={{ justifyContent: 'space-between', paddingRight: '0px' }}>
        <StatusPieChart status={status} />
        <UploadTime>Uploaded on {dateShot} CEST</UploadTime>
      </Footer>
    </Container>
    <PackageDialog open={isOpen} onClose={() => setIsOpen(false)}>
      <PackageDialogContent>
        <Container>
          <Header>
            <Title>{packageName}</Title>
            <RatingSection>{renderStars(status)}</RatingSection>
          </Header>
          <Description>{description}</Description>
          <Footer style={{ justifyContent: 'space-between', paddingRight: '0px' }}>
            <StatusPieChart status={status} />
            <UploadTime>Uploaded on {dateShot} CEST</UploadTime>
          </Footer>
        </Container>
        <PackageDialogDelimiter />
        <PackageDetails
                    uploadMetadataId={uploadMetadataId}
                    upload={upload}
                    directoryName={directoryName}
                    onStatusUpdate={handleStatusUpdate} // Pass the handler to PackageDetails
                  />
        <AbsCrossButton onClick={() => setIsOpen(false)} />
      </PackageDialogContent>
    </PackageDialog>
  </>
  );
};

export default PackageSection;
