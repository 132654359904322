import http from "./http-common";
import { getAuthToken } from "./authService";

const updateStatusForDirectories = async (folderName, uploadStatus) => {
  if (!folderName) {
    throw new Error("Folder name is required to update status.");
  }

  const token = await getAuthToken();

  const data = {
    uploadStatus,
    directories: [folderName],
  };

  try {
    const response = await http.put("api/v1/catdv/status", data, {
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": "api-key", // Assuming the API key is returned by `getAuthToken`
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};

const StatusService = {
  updateStatusForDirectories,
};

export default StatusService;
