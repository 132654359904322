import React from "react";
import { CurationTitle, CurationInputWrapper } from "@/components/Curation/Curation.elements";
import { Container } from "@/globalStyles";

const CurationPage = (props) => {
  return (
    <Container>
      <CurationInputWrapper>
        <CurationTitle>Files</CurationTitle>
      </CurationInputWrapper>
      
    </Container>
  );
};

export default CurationPage;