/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Container } from "../../globalStyles";
import CounterStats from "../../components/Stats/Counter/CounterStats";
import PieCharts from "../../components/Stats/PieCharts";
import { ChartsBlock } from "../../components/Stats/Counter/CountersBlock.elements";
import Rating from "../../components/Util/Rating/Rating";
import { FormRatingWrapper } from "../../components/Util/Rating/Rating.elements";
import TimeFrameSelector from "../../components/Stats/TimeFrameSelector";
import { useContributorsState, useStatsActions, useStatsState } from "@/store";

const StatGroup = (props) => {
  const { stats } = useStatsState();
  const { fetchStats } = useStatsActions();
  const { selectedContributorId } = useContributorsState();
  const [[fromDate, toDate], setDates] = useState([]);

  useEffect(() => {
    const params = {
      contributors: selectedContributorId,
      fromDate: fromDate,
      toDate: toDate,
    };
    fetchStats(params);
  }, [selectedContributorId, fromDate, toDate, fetchStats]);

  const handleSelectTimeFrame = ({ fromDate, toDate }) => {
    setDates([fromDate, toDate]);
  };

  return (
    <>
      <Container>
        <Container>
          <ChartsBlock>
            <FormRatingWrapper>
              <TimeFrameSelector
                onSelectTimeFrame={handleSelectTimeFrame}
                defaultValue="all"
              />
              <Rating rating={stats.avgRating} />
            </FormRatingWrapper>
          </ChartsBlock>
          <CounterStats
            uploads={stats.uploads ? stats.uploads : 0}
            clips={stats.clips ? stats.clips : 0}
          />
          <PieCharts stats={stats} />
        </Container>
        {/* <Container>
          <DashboardPackageGroupSelection 
          contributor={selectedContributorId}/>
        </Container> */}
      </Container>
    </>
  );
};

export default StatGroup;
