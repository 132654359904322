import styled from "@emotion/styled";

export const CurationInputWrapper = styled.div`
  flex-grow: 1;
  align-items: center;
  gap: 16px;
  padding: ${({ theme }) => theme.spacing('large')};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;           // Stack elements vertically on mobile devices
    align-items: stretch;             // Ensure the items stretch on mobile
    gap: 10px;  
    width: 100%;
    padding: 10px; 
  }
`;

export const CurationTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.h2}; /* Use large font size from the theme */
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary}; /* Use primary text color from the theme */
  margin-bottom: ${({ theme }) => theme.spacing('medium')}; /* Space between title and counters */
  margin-left: 5px;
`;