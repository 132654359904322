import { PlusIcon } from "@/icons";
import { Button, IconButton } from "@mui/material";
import { useIsTablet } from "../hooks";

export const UploadButton = ({ handleOpenModal }) => {
  const isTablet = useIsTablet();

  return isTablet ? (
    <IconButton
      onClick={handleOpenModal}
      color="secondary"
      sx={{ flexShrink: 0 }}
    >
      <PlusIcon />
    </IconButton>
  ) : (
    <Button
      onClick={handleOpenModal}
      color="secondary"
      sx={{ flexShrink: 0 }}
    >
      UPLOAD FILES
    </Button>
  )
}
