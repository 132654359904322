import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showLoading, hideLoading } from "./loadingSlice";
import { showAlert, hideAlert } from "./alertSlice";
import PackageService from "@/Service/PackageService";

// Fetch source files
export const fetchSourceFiles = createAsyncThunk(
  "sourceFiles/fetchSourceFiles",
  async ({page = 0, size = 10 }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      const response = await PackageService.getSourceFiles(page, size); // Fetch source files from service
      dispatch(hideLoading());
      return response; // Return response data
    } catch (error) {
      console.error("[fetchSourceFiles] Error:", error);
      dispatch(hideLoading());
      dispatch(showAlert("error", "There was an error while fetching source files."));
      return rejectWithValue(error);
    }
  }
);

const sourceFilesSlice = createSlice({
  name: "sourceFiles",
  initialState: {
    sourceFiles: [], // Source files data
    totalPages: 0,   // Total number of pages
    currentPage: 0,  // Current page for pagination
    searchStatus: "All", // Filter status (e.g., Approved, Rejected, Not Curated)
    status: "idle",  // Fetching status (idle, loading, succeeded, failed)
    error: null,     // Error state
  },
  reducers: {
    setSourceFilesStatus: (state, action) => {
      console.log("[setSourceFilesStatus] Status Filter:", action.payload);
      state.searchStatus = action.payload;
      state.currentPage = 0; // Reset to the first page when the status filter changes
    },
    setSourceFilesPage: (state, action) => {
      console.log("[setSourceFilesPage] Page:", action.payload);
      state.currentPage = action.payload;
    },
    resetSourceFilesFilters: (state) => {
      console.log("[resetSourceFilesFilters] Resetting filters.");
      state.searchStatus = "All";
      state.currentPage = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSourceFiles.pending, (state) => {
        console.log("[fetchSourceFiles.pending] Fetching source files...");
        state.status = "loading";
      })
      .addCase(fetchSourceFiles.fulfilled, (state, action) => {
        console.log("[fetchSourceFiles.fulfilled] Fetched source files:", action.payload.content);
        state.status = "succeeded";
        state.sourceFiles = action.payload.content; // Assign source files content
        state.totalPages = action.payload.totalPages; // Assign total pages
      })
      .addCase(fetchSourceFiles.rejected, (state, action) => {
        console.error("[fetchSourceFiles.rejected] Error:", action.error.message);
        state.status = "failed";
        state.error = action.error.message; // Set the error message
      });
  },
});

export const {
  setSourceFilesStatus,
  setSourceFilesPage,
  resetSourceFilesFilters,
} = sourceFilesSlice.actions;

export const useSourceFilesState = () => {
  const state = useSelector((state) => state.sourceFiles);
  console.log("[useSourceFilesState] Current state:", state);
  return state;
};

export const useSourceFilesActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    fetchSourceFiles: (params) => {
      console.log("[useSourceFilesActions] Fetching source files with params:", params);
      return dispatch(fetchSourceFiles(params));
    },
    setSourceFilesStatus: (status) => {
      console.log("[useSourceFilesActions] Setting source files status:", status);
      return dispatch(setSourceFilesStatus(status));
    },
    setSourceFilesPage: (page) => {
      console.log("[useSourceFilesActions] Setting source files page:", page);
      return dispatch(setSourceFilesPage(page));
    },
    resetSourceFilesFilters: () => {
      console.log("[useSourceFilesActions] Resetting source files filters.");
      return dispatch(resetSourceFilesFilters());
    },
  }), [dispatch]);
};

export default sourceFilesSlice.reducer;
