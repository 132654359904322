import { useMobileMenuActions, useMobileMenuState } from "@/store/mobileMenuSlice";
import { DashboardIcon, UploadsIcon, ClipsIcon, ContributorsIcon, CollapseIcon, CurationIcon } from "@/icons"

import {
  NavPlaceholder,
  Nav,
  NavIcon,
  NavLink,
  NavDivider,
  NavContainer,
  NavHeader,
  CollapseButton,
  MobileCloseButton,
} from "./Navbar.elements";
import { useAuthState } from "@/store";
import { Logo } from "../Logo/Logo";
import { useIsMobile } from '../hooks';


export const Navbar = () => {
  const isMobile = useIsMobile();
  const { currentUser } = useAuthState();
  const { role } = currentUser || {};
  const isAdmin = role === "admin";
  const isManager = role === "manager";
  const { isMobileMenuOpen, isSidebarCollapsed } = useMobileMenuState();
  const { toggleMobileMenu, toggleSidebar } = useMobileMenuActions();

  if (!currentUser) {
    console.log("current user is: ", currentUser);
    return null;
  } else {
    return (
      <NavPlaceholder isCollapsed={isSidebarCollapsed}>
        <NavContainer isMobileMenuOpen={isMobileMenuOpen}>
          <NavHeader>
            <Logo />
            {isMobile ? (
              <MobileCloseButton onClick={toggleMobileMenu} />
            ) : (
              <CollapseButton isCollapsed={isSidebarCollapsed} onClick={toggleSidebar}>
                <CollapseIcon />
              </CollapseButton>
            )}
          </NavHeader>
          <Nav>
            <NavLink to="/" onClick={toggleMobileMenu} >
              <NavIcon as={DashboardIcon} />
              Dashboard
            </NavLink>
            <NavLink to="/uploads" onClick={toggleMobileMenu}>
              <NavIcon as={UploadsIcon} />
              Uploads
            </NavLink>
            <NavLink to="/curation" onClick={toggleMobileMenu}>
              <NavIcon as={CurationIcon} />
              Curation
            </NavLink>
            <NavLink to="/clips" onClick={toggleMobileMenu}>
              <NavIcon as={ClipsIcon} />
              Clips
            </NavLink>

            {(isAdmin || isManager) && (
              <>
                <NavDivider/>
                <NavLink to="/manageUsers" onClick={toggleMobileMenu}>
                  <NavIcon as={ContributorsIcon} />
                  Users
                </NavLink>
              </>
            )}
          </Nav>
        </NavContainer>
      </NavPlaceholder>
    );
  }
};
